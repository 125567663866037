import React from 'react'
import Marquee from "react-fast-marquee";
import images from '../../resources/images';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';

const BrandsSlider = () => {

    const clientSectiOne = [
        {
            id: 'client1',
            img: images.client,
        },
        {
            id: 'client2',
            img: images.client1,
        },
        {
            id: 'client3',
            img: images.client2,
        },
        {
            id: 'client4',
            img: images.client3,
        },
        {
            id: 'client5',
            img: images.client4,
        },
        {
            id: 'client6',
            img: images.client5,
        },
        {
            id: 'client7',
            img: images.client6,
        },
        {
            id: 'client8',
            img: images.client7,
        },
        {
            id: 'client8',
            img: images.client8,
        },
        {
            id: 'client9',
            img: images.client9,
        },
        {
            id: 'client10',
            img: images.client10,
        },
    ];



    const clientSectiTwo = [
        {
            id: 'client11',
            img: images.client11,
        },
        {
            id: 'client12',
            img: images.client12,
        },
        {
            id: 'client13',
            img: images.client13,
        },
        {
            id: 'client14',
            img: images.client14,
        },
        {
            id: 'client15',
            img: images.client15,
        },
        {
            id: 'client16',
            img: images.client16,
        },
        {
            id: 'client17',
            img: images.client17,
        },
        {
            id: 'client18',
            img: images.client18,
        },
        {
            id: 'client19',
            img: images.client19,
        },
        {
            id: 'client20',
            img: images.client20,
        },
        {
            id: 'client21',
            img: images.client21,
        },
        {
            id: 'client22',
            img: images.client22,
        },
    ];

    return (
        <React.Fragment>
            <Container fluid>
                <div className="heading-container">
                    <h2 className='brandingHeading'>Our Experience Through Time</h2>
                </div>
                
                   
                        <Marquee direction="right">
                            {clientSectiOne?.map(({ id, img }) => (
                                <img
                                    key={id}
                                    src={img}
                                    alt={`Client ${id}`}
                                    style={{ marginRight: '500px' }}
                                />
                            ))}
                        </Marquee>
                <Row className='mt-5'>
                    <Col xs={12} sm={12}>
                        <Marquee direction="left">
                            {clientSectiTwo?.map(({ id, img }) => (
                                <img
                                    key={id}
                                    src={img}
                                    alt={`Client ${id}`}
                                    style={{ marginRight: '100px' }}
                                />
                            ))}
                        </Marquee>
                    </Col>
                </Row>
            </Container>
            <div className='d-flex justify-content-center justify-content-md-end me-4 viewCaseButton'>
                <Button className='viewCaseBtn' variant="outline-light">View Case Studies</Button>
            </div>

        </React.Fragment>
    )
}

export default BrandsSlider;
